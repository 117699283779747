import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import IconButtonsStack from '@fingo/lib/components/stacks/IconButtonsStack';
import AssignmentIcon from '@mui/icons-material/Assignment';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ExtensionIcon from '@mui/icons-material/Extension';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import useFetchPresignedUrl from '@fingo/lib/hooks/useFetchPresignedUrl';
import { useInvoiceRequirementsStates } from '@fingo/lib/hooks';
import useTheme from '@mui/material/styles/useTheme';
import RiskRestrictionsDialog from './dialogs/RiskRestrictionsDialog';
import { buildComplianceOperationDetailUrl, calculateBFStatus, riskRestrictionRequirementsIconColor } from '../../helpers/treasury';

const TransferInvoiceRequirements = (
  { invoice, disabled, selectedRequirements, filterRestriction },
) => {
  const [openRiskRestrictionsDialog, setOpenRiskRestrictionsDialog] = useState(false);
  const [,, fetchGivenId] = useFetchPresignedUrl();
  const openUrl = useCallback((model) => fetchGivenId(model.globalAppId), [fetchGivenId]);
  const { isFirstOperation, compliance, company, contract,
    filteredRestrictions, BFStatus, regcheqId,
    assignment } = useInvoiceRequirementsStates(invoice, filterRestriction);
  const theme = useTheme();
  const SUCCESS = theme.palette.success.main;
  const ERROR = theme.palette.error.A600;

  const actualBFStatus = useMemo(
    () => calculateBFStatus(BFStatus, !isFirstOperation, compliance),
    [BFStatus, isFirstOperation, compliance],
  );

  const allRequirements = [
    {
      id: 'contract',
      tooltipTitle: 'Contrato',
      disabled: !contract,
      color: contract ? SUCCESS : ERROR,
      onClick: () => openUrl(contract),
      icon: <AssignmentIcon />,
    },
    {
      id: 'cession_certificate',
      tooltipTitle: 'Certificado de cesión',
      disabled: !assignment,
      color: assignment ? SUCCESS : ERROR,
      onClick: () => openUrl(assignment),
      icon: <VerifiedUserIcon />,
    },
    {
      id: 'regcheq',
      tooltipTitle: actualBFStatus?.tooltip,
      disabled: !regcheqId,
      color: actualBFStatus?.color || '#808080',
      onClick: () => window.open(buildComplianceOperationDetailUrl(regcheqId)),
      icon: <ExtensionIcon />,
    },
    {
      id: 'risk',
      tooltipTitle: 'Riesgo',
      disabled: !filteredRestrictions.length,
      color: riskRestrictionRequirementsIconColor(filteredRestrictions),
      onClick: () => setOpenRiskRestrictionsDialog(!openRiskRestrictionsDialog),
      icon: <NewReleasesIcon />,
    },
  ];

  const requirements = selectedRequirements
    ? allRequirements.filter((req) => selectedRequirements.includes(req.id))
    : allRequirements;

  return (
    <>
      <IconButtonsStack items={requirements} />
      {openRiskRestrictionsDialog && (
        <RiskRestrictionsDialog
          open={openRiskRestrictionsDialog}
          handleClose={() => setOpenRiskRestrictionsDialog(false)}
          companyId={company?.id}
          restrictions={filteredRestrictions}
          disabled={disabled}
        />
      )}
    </>
  );
};

TransferInvoiceRequirements.propTypes = {
  invoice: PropTypes.shape({
    assignmentSet: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      certificate: PropTypes.string.isRequired,
      globalAppId: PropTypes.string.isRequired,
    })),
    company: PropTypes.shape({
      rut: PropTypes.string.isRequired,
      masterEntity: PropTypes.shape({
        id: PropTypes.string.isRequired,
        compliance: PropTypes.shape({
          requirementsClean: PropTypes.bool.isRequired,
          information: PropTypes.string.isRequired,
          readyForTransfer: PropTypes.bool.isRequired,
          status: PropTypes.string.isRequired,
          regcheqId: PropTypes.string.isRequired,
        }),
      }),
      companyevaluationrestrictionsSet: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          restriction: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          status: PropTypes.string.isRequired,
          resolutionExplanation: PropTypes.string.isRequired,
        }),
      ).isRequired,
      documents: PropTypes.arrayOf(
        PropTypes.shape({
          documentType: PropTypes.string.isRequired,
          exists: PropTypes.bool.isRequired,
          lastDate: PropTypes.string,
          lastFile: PropTypes.string,
        }),
      ).isRequired,
    }),
    isFirstOperation: PropTypes.bool.isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
  selectedRequirements: PropTypes.arrayOf(PropTypes.string),
  filterRestriction: PropTypes.oneOf(['Risk', 'Operations']),
};

TransferInvoiceRequirements.defaultProps = {
  disabled: false,
  selectedRequirements: null,
  filterRestriction: null,
};

export default TransferInvoiceRequirements;
