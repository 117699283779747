import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useBooleanState, useSnackBars } from '@fingo/lib/hooks';
import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import {
  INVOICE_TRANSFER_INFO,
  MANUAL_MONEY_TRANSFERS,
} from '@fingo/lib/graphql';
import { formatYearMonthDay } from '@fingo/lib/helpers';
import dayjs from '@fingo/lib/config/dayjs';

const ManualTransferDialog = ({
  selectedDocumentIds,
  cleanDocuments,
  data,
}) => {
  const sumAmountToTransfer = useMemo(() => {
    if (!selectedDocumentIds.length) return 0;
    return data
      .filter((inv) => selectedDocumentIds.includes(inv.id))
      .map(
        (inv) => inv.paymentDiscounts.paymentAmountAfterNegativeSurplus.amount,
      )
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  }, [selectedDocumentIds]);
  const [openDialog, toggleDialog, , closeDialog] = useBooleanState();
  const { addAlert } = useSnackBars();
  const [sendManualTransfer, manualTransferData] = useMutation(
    MANUAL_MONEY_TRANSFERS,
    {
      variables: {
        invoiceIds: selectedDocumentIds,
        transferDatetime: formatYearMonthDay(dayjs()),
      },
      refetchQueries: [INVOICE_TRANSFER_INFO],
      awaitRefetchQueries: true,
      onCompleted: () => {
        closeDialog();
        cleanDocuments();
        addAlert({
          severity: 'success',
          message: 'Documentos marcados como transferidos',
        });
      },
      onError: () => {
        addAlert({
          severity: 'error',
          message: 'Ha ocurrido un error',
        });
      },
    },
  );
  return (
    <>
      <Button
        color="primary"
        variant="contained"
        size="small"
        disableElevation
        onClick={toggleDialog}
        disabled={!selectedDocumentIds.length || sumAmountToTransfer < 0}
        id="toggle-download-portfolio"
      >
        Giro manual
      </Button>
      <FingoDialog
        open={openDialog}
        handleClose={closeDialog}
        maxWidth="md"
        fullWidth
        title="Giro manual"
      >
        <Box justifyContent="right" display="flex">
          <LoadingButton
            color="primary"
            variant="contained"
            size="small"
            disableElevation
            id="toggle-download-portfolio"
            onClick={sendManualTransfer}
            loading={manualTransferData.loading}
          >
            Enviar a conciliación sin enviar mail de giro
          </LoadingButton>
        </Box>
      </FingoDialog>
    </>
  );
};

ManualTransferDialog.propTypes = {
  selectedDocumentIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  cleanDocuments: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default ManualTransferDialog;
